const postsQuery = (postsPerPage, offset, activeFilters) => (
    `query Posts {
        posts(where: {
            offsetPagination: {size: ${postsPerPage}, offset: ${offset}},
            activeFilters: ${activeFilters}
        }) {
            edges {
                node {
                    author {
                        node {
                            avatar {
                                url
                            }
                            name
                        }
                    }
                    date
                    post {
                        excerpt
                        featuredImage {
                            sizes(size: LARGE)
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        video
                        author {
                            ... on CisilionAuthor {
                                title
                                author {
                                    company
                                    linkedinProfile
                                    position
                                    featuredImage {
                                        sizes(size: MEDIUM)
                                        sourceUrl(size: MEDIUM)
                                        srcSet(size: MEDIUM)
                                    }
                                }
                            }
                        }
                    }
                    slug
                    title
                    terms {
                        nodes {
                            name
                            termTaxonomyId
                        }
                    }
                }
            }
            pageInfo {
                total
            }
        }
    }`
);

export default postsQuery;
